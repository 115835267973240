import React, { useState, useCallback, useRef, useEffect } from 'react';
import Confetti from 'react-confetti';
import { FaLink, FaEllipsisH } from 'react-icons/fa';
import './App.css';
import leapyearLogo from './images/leapyear.png';
import pioneerLogo from './images/pioneer.png';
import docsIcon from './images/docs.png';
import slackIcon from './images/slack.png';
import diffIcon from './images/diff.png';
import writeIcon from './images/write.png';
import timerIcon from './images/timer2.png';
import DemoComponent from './components/DemoComponent';
import toast, { Toaster } from 'react-hot-toast';

function App() {
  const [email, setEmail] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const dismissTimerRef = useRef(null);
  const menuRef = useRef(null);
  const menuHighlightTimerRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Show confetti and notification immediately
    setShowConfetti(true);
    toast.success('Signup successful!', {
      position: 'top-center',
      style: {
        backgroundColor: '#181818',
        color: '#7FF257',
      },
      iconTheme: {
        primary: '#7FF257',
        secondary: '#181818',
      },
    });
  
    // Send the data to Google Sheets in the background
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwMKCPKUxwiIOH532HmzMfNBdNuSDC--hNaWn6_AhboVPZw7XONHRs00T7gfEsyz9Ok/exec', {
        method: 'POST',
        body: new URLSearchParams({ email }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      console.log("Form submitted successfully");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      toast.error('There was an error submitting the form.');
    }
  
    // Reset the form
    setEmail('');
  
    // Stop confetti after a delay
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000); // 3 seconds
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast.success('Link copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy link');
    });
  };

  const actions = [
    {
      text: "How do I export data?",
      tooltip: "Hey! You can find 'Export Data' inside this menu.",
      target: '#menu-button',
      menuItem: 'export-data',
      action: () => {} // Remove auto-opening menu
    },
    {
      text: "Can I share this somehow?",
      tooltip: "Yes, click here to copy the page link to your clipboard!",
      target: '#copy-link',
      action: handleCopyLink
    },
    {
      text: "Where's the brand kit?",
      tooltip: "Open the menu and choose 'Download Brand Kit' to get our assets.",
      target: '#menu-button',
      menuItem: 'download-brand-kit',
      action: () => {} // Remove auto-opening menu
    },
    {
      text: "How turn off auto-play?",
      tooltip: "Hi! Just click this menu and you'll see the auto-play toggle.",
      target: '#menu-button',
      menuItem: 'dark-mode-toggle',
      action: () => {} // Remove auto-opening menu
    },
    {
      text: "How do I put this on my site?",
      tooltip: "Enter your email and click 'Sign up'!",
      target: '#email',
      action: () => document.getElementById('email').focus()
    },
    {
      text: "So what else can Torchy do?",
      tooltip: "You can see our main features below.",
      target: '.features',
      action: () => document.querySelector('.features').scrollIntoView({ behavior: 'smooth' })
    },
    {
      text: "Who's making this?",
      tooltip: "You can see related information about the founders below.",
      target: '.founders',
      action: () => document.querySelector('.founders').scrollIntoView({ behavior: 'smooth' })
    },
    {
      text: "How can I get in touch?",
      tooltip: "Click here to send us an email. We'd love to hear from you!",
      target: '.contact-link a',
      action: () => window.location.href = 'mailto:founders@naturalui.com'
    }
  ];

  const handleActionPerformed = useCallback((index) => {
    const action = actions[index];
    setCurrentAction(action);
    
    // Remove previous highlights and event listeners
    document.querySelectorAll('.highlighted').forEach(el => {
      el.classList.remove('highlighted');
      el.removeEventListener('click', handleHighlightedElementClick);
    });
    
    // Add highlight to current target
    const targetElement = document.querySelector(action.target);
    if (targetElement) {
      targetElement.classList.add('highlighted');
      targetElement.addEventListener('click', () => handleHighlightedElementClick(action));
    }

    // Highlight menu item if applicable
    if (action.menuItem) {
      const menuItem = document.querySelector(`#${action.menuItem}`);
      if (menuItem) {
        menuItem.classList.add('highlighted');
        menuItem.addEventListener('click', () => handleMenuItemClick(action));

        // Set a timer to remove the highlight after 8 seconds
        if (menuHighlightTimerRef.current) {
          clearTimeout(menuHighlightTimerRef.current);
        }
        menuHighlightTimerRef.current = setTimeout(() => {
          removeMenuItemHighlight(menuItem);
        }, 8000);
      }
    }

    // Perform the action
    action.action();
  }, [actions]);

  const removeMenuItemHighlight = useCallback((menuItem) => {
    if (menuItem) {
      menuItem.classList.add('fading');
      setTimeout(() => {
        menuItem.classList.remove('highlighted', 'fading');
      }, 300);
    }
    setCurrentAction(null);
  }, []);

  const handleHighlightedElementClick = useCallback((action) => {
    if (action.menuItem) {
      // For menu-type actions, start a timer to remove the highlight from the menu button
      if (dismissTimerRef.current) {
        clearTimeout(dismissTimerRef.current);
      }
      dismissTimerRef.current = setTimeout(() => {
        const menuButton = document.querySelector(action.target);
        if (menuButton) {
          menuButton.classList.add('fading');
          setTimeout(() => {
            menuButton.classList.remove('highlighted', 'fading');
          }, 300);
        }
        setCurrentAction(null);
      }, 3000);
    } else {
      // For instant actions, remove highlight and tooltip after a short delay
      setTimeout(() => {
        handleActionComplete();
      }, 1000);
    }
  }, []);

  const handleMenuItemClick = useCallback((action) => {
    // Clear the menu highlight timer if an item is clicked
    if (menuHighlightTimerRef.current) {
      clearTimeout(menuHighlightTimerRef.current);
    }

    // Remove highlight from the specific menu item and tooltip after a short delay
    setTimeout(() => {
      const menuItem = document.querySelector(`#${action.menuItem}`);
      removeMenuItemHighlight(menuItem);
    }, 1000);
  }, [removeMenuItemHighlight]);

  const handleActionComplete = useCallback(() => {
    if (dismissTimerRef.current) {
      clearTimeout(dismissTimerRef.current);
    }
    setCurrentAction(null);
    document.querySelectorAll('.highlighted').forEach(el => {
      el.classList.add('fading');
      setTimeout(() => {
        el.classList.remove('highlighted', 'fading');
        el.removeEventListener('click', handleHighlightedElementClick);
      }, 300);
    });
  }, [handleHighlightedElementClick]);

  const handleExportData = () => {
    // Implement export data functionality
    console.log('Exporting data...');
    // toast.success('Data exported successfully!');
  };

  const handleDownloadBrandKit = () => {
    // Implement download brand kit functionality
    console.log('Downloading brand kit...');
    // toast.success('Brand kit downloaded successfully!');
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    setCurrentAction(null);
  };

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
      // Don't clear the menu highlight timer when closing the menu
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (menuHighlightTimerRef.current) {
        clearTimeout(menuHighlightTimerRef.current);
      }
    };
  }, [handleClickOutside]);

  return (
    <div>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              background: '#181818',
              color: '#7FF257',
            },
            iconTheme: {
              primary: '#7FF257',
              secondary: '#181818',
            },
          },
          error: {
            style: {
              background: '#181818',
              color: '#FF6C3D',
            },
            iconTheme: {
              primary: '#FF6C3D',
              secondary: '#181818',
            },
          },
        }}
      />
      {showConfetti && <Confetti />}
      <header className="App-header">
        <div className="header-content">
          <div className="logo-header">
            <h3 id="natural-logo">🔥 Torchy</h3>
          </div>
          <div className="nav-actions">
            <div className="contact-link">
              <a href="mailto:founders@naturalui.com">Contact us</a>
            </div>
          </div>
        </div>
        <div className="hero">
          <div className="text-content">
            <h1 id="header">Keep internal docs <span className="highlight">up to date</span></h1>
            <p id="sub-header">A Slack team member that knows your company inside and out.</p>
            <form onSubmit={handleSubmit} className="email-form">
              <div className="input-button-group">
                <input
                  id="email"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button">
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
          <div className="image-container">
            <DemoComponent />
          </div>
        </div>
      </header>
      <main>
        <div className="features">
          <div className="feature-item">
            <img src={docsIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Automatically updated docs</h2>
            <p id="feature-text">Torchy is up to speed with every change in your company.</p>
          </div>
          <div className="feature-item">
            <img src={timerIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Solve problems faster</h2>
            <p id="feature-text">Torchy can get you the info you need at warp speed.</p>
          </div>
          <div className="feature-item">
            <img src={slackIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">@Torchy in Slack </h2>
            <p id="feature-text">Torchy is available to answer complex questions, 24/7.</p>
          </div>
        </div>

        <div className="founders">
          <h2 id="logo-text">Trusted by...</h2>
          <div className="logos">
            <img src={leapyearLogo} id="leapyear" alt="Leapyear" />
            <img src={pioneerLogo} id="pioneer" alt="YC Pioneer Program" />
          </div>
        </div>

        <div className="main-features">

        <h2 id="features-text">Features</h2>

        <div className="features-section">
        
        <div className="features-2">
          <div className="feature-item-2">
            <img src={docsIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">No more outdated docs</h2>
            <p id="feature-text">Torchy learns about your company and can update documentation about products, processes, and more.</p>
          </div>
          <div className="feature-item-2">
            <img src={diffIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Diff view</h2>
            <p id="feature-text">Torchy allows you to observe and edit changes between old and new doc versions.</p>
          </div>
        </div>
        <div className="features-2">
          <div className="feature-item-2">
            <img src={slackIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Grounded in context</h2>
            <p id="feature-text">Torchy generates intelligent suggestions from Slack conversations and meeting notes.</p>
          </div>
          <div className="feature-item-2">
            <img src={writeIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Adjustable writing style</h2>
            <p id="feature-text">Torchy can adapt its writing style to your team's preferences and templates.</p>
          </div>
        </div>

        </div>
        </div>

        <div className="main-features">
        <h2 id="features-text">How it works</h2>
        <p className="small-grey-text">Spoiler alert: no coding required!</p>
        <div className="how">
        
          <div className="feature-item">
            <img src={slackIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Add Slack bot</h2>
            <p id="feature-text">Torchy is ready to join any channel you need.</p>
          </div>
          <div className="feature-item">
            <img src={docsIcon} id="icon" alt="Feature Icon" />
            <h2 id="feature-heading">Connect to docs</h2>
            <p id="feature-text">Easily integrate Torchy with Google Docs, Notion, or any other doc platform.</p>
          </div>

        </div>

        </div>

        <div className="story">
          <h1 id="story-heading">A smart <span className="highlight">personal assistant</span></h1>
          <div className="story-content">
            <p id="story-text">When your team is pushing to get another feature out the door, the last thing they want is to be lost in a sea of old messages and outdated docs. That's why we are building Torchy - a personal assistant that organizes and surfaces key information from across your company.</p>
            <ul>
              <li><strong>Docs without the hassle:</strong> Who wants to write up a doc about the 100 message product conversation they had last week? Torchy saves hours of back and forth so your team can get back to what they do best.</li>
              <li><strong>Insights:</strong> Remember how you solved that bug six months ago? Neither do we. Thankfully, Torchy has a good memory.</li>
              <li><strong>Easy:</strong> A few clicks and Torchy is ready to go. It's that simple.</li>
            </ul>
            <p id="story-text">Our goal is to eliminate hours wasted on preventable mistakes, writing up conclusions from conversations, and searching through droves of messages and docs to find that one pesky answer you need. Join us in our mission to make your team more productive than ever before.</p>
            <h3>Colin and Grayson</h3>
            <p>Founders of Torchy</p>
          </div>
        </div>

      </main>
      <footer>
        <div className="inner-footer">
          <div className="footer-logo">
            <h3 id="natural-logo">🔥</h3>
            <div className="contact-link"><a href="mailto:founders@naturalui.com">Contact us</a></div>
            <div className="contact-link"><a href="https://twitter.com/joinnatural" className="contact-link" target="_blank" rel="noopener noreferrer">Twitter</a></div>
             <div className="contact-link"><a href="https://www.linkedin.com/company/naturalui" className="contact-link" target="_blank" rel="noopener noreferrer">LinkedIn</a></div>
          </div>
          <div className="input-email-form-2">
            <form onSubmit={handleSubmit} className="email-form-2">
              <div className="input-button-group">
                <input
                  id="email-2"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="input-button">
                  Join Waitlist
                </button>
              </div>
            </form>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;