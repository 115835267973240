import React from 'react';
import { FaCheck, FaTimes, FaSlack } from 'react-icons/fa';
import docsIcon from '../images/docs.png';
import slackIcon from '../images/slack.png';
import './DemoComponent.css';

const DemoComponent = () => {
  return (
    <div className="demo-container">
      <img src={docsIcon} id="icon" alt="Google Docs & Notion" />
      <div className="demo-header">
        <p className="demo-problem-text"><s>Users don't like the current design</s></p>
      </div>
      <div className="demo-content">
        <div className="demo-suggestion-header">
          <h2 id="demo-section-header">Suggestion</h2>
          <div className="demo-actions">
            <button className="demo-accept"><FaCheck /></button>
            <button className="demo-reject"><FaTimes /></button>
          </div>
        </div>
        <p id="demo-suggestion-text">users think the current page has too much text</p>
        <div className="demo-sources">
          <div className="demo-source">
          <img src={slackIcon} id="icon-demo" alt="Feature Icon" />

            <span>John in #product</span>
          </div>
          <div className="demo-source">
          <img src={slackIcon} id="icon-demo" alt="Feature Icon" />

            <span>Zapier in #feedback</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoComponent;